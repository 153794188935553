import React from 'react';
import {Button, Card} from 'antd';
import LinkedThemeStyle from '../../less/linked-theme.module.less';
import RightOutlined from '@ant-design/icons/lib/icons/RightOutlined';
import {Link, useIntl} from 'gatsby-plugin-intl';
import PropTypes from 'prop-types';
import {navigate} from "@reach/router";

const LinkedTheme = ({title, path, year}) => {
  const intl = useIntl();

  return (
    <Card hoverable
          onClick={() => navigate(`/${intl.locale}/home/${path}/`)}>
      <h4 className={LinkedThemeStyle.title}>
        <Link to={`/home/${path}`}>
          {title}
          <span
            style={{fontSize: '14px', fontWeight: 'normal', display: 'block'}}>
          ({year})
        </span>
        </Link>
      </h4>
      <Button className={LinkedThemeStyle.primaryButton} type={'link'}
              tabIndex={-1}
              aria-hidden={true}
              style={{padding: "0.4rem 0rem"}}>
        {intl.formatMessage({id: 'general_discover'})}
        <RightOutlined role={''} aria-label={''}/>
      </Button>
    </Card>
  );
};

LinkedTheme.propTypes = {
  title: PropTypes.string,
  path: PropTypes.string,
  year: PropTypes.string
};

export default LinkedTheme;
