import * as React from "react"
import Layout from "../components/general/layout"
import ProjectDetailStyle from "../less/project-detail.module.less"
import {Link} from "gatsby-plugin-intl"
import {graphql} from "gatsby"
import Container from "../components/general/container"
import useIntl from "../helpers/useIntl"
import TranslateHtml from "../components/general/translateHtml"
import SectionStyle from "../less/section.module.less"
import CardGrid from "../components/general/card-grid"
import LinkedTheme from "../components/general/linked-theme"
import ProjectCard from "../components/general/project-card"
import {shuffle} from "lodash"

const ShowcaseDetail = ({data}) => {
  const intl = useIntl()

  const showcase = data.budgetShowcase
  let otherShowcases = data.allBudgetShowcase.nodes

  if (otherShowcases) {
    otherShowcases = shuffle(otherShowcases)
    otherShowcases = otherShowcases.slice(0, 2)
    otherShowcases = otherShowcases.filter((otherShowcase) => otherShowcase.id !== showcase.id)
  }

  const getPath = (category) => {
    let parent

    if (!category) {
      return;
    }

    if (category.fields.parent) {
      parent = getPath(category.fields.parent)
    }

    return parent ? `${parent}/${category.originalId}` : `${category.originalId}`
  }

  const budgetCategories = showcase.fields.budgetShowcasePosts.map(
    ({fields, year}) => {
      let budget = fields.budget
      let category = fields.category
      let path = getPath(category.fields.parent)
      path = budget.expenses ? `${year}/expenses/${path}` : `${year}/revenue/${path}`

      return {
        ...category,
        path,
        year,
      }
    }).sort((a, b) => b.year - a.year)

  const breadcrumbs = []
  let breadcrumb = {
    ...showcase,
    label: intl.translate(showcase, "title"),
    url: '/showcase/' + showcase.slug
  };
  breadcrumbs.push({
    label: intl.formatMessage({id: 'detail_projects'}),
    url: '/showcases'
  });
  breadcrumbs.push(breadcrumb);

  return (
    <Layout
      isMarked={true}
      heroFluidImage={showcase.featuredImg}
      heroBgColor={"white"}
      heroTitle={intl.translate(showcase, "title")}
      heroSubtitle={intl.translate(showcase, "subtitle")}
      heroSize={"large"}
      titleOffset={true}
      showBreadcrumbs={true}
      breadcrumbs={breadcrumbs}
    >
      <Container size={"small"}>
        <div className={ProjectDetailStyle.detail}>
          <article className={ProjectDetailStyle.article}>
            <TranslateHtml object={showcase} prop={"body"}/>
          </article>
        </div>
      </Container>

      <section className={SectionStyle.isHighlighted}>
        <Container size={"small"}>
          <h3>{intl.formatMessage({id: "general_related"})}</h3>
          <CardGrid>
            {budgetCategories.map((category, i) => (
              <LinkedTheme key={i}
                           path={category.path}
                           title={intl.translate(category, "label")}
                           year={category.year}/>
            ))}
          </CardGrid>
        </Container>
      </section>

      <section>
        <Container size={"small"}>
          <h3>{intl.formatMessage({id: "projects_related"})}</h3>
          <CardGrid>
            {otherShowcases.map((p, i) => (
              <ProjectCard
                key={i}
                title={intl.translate(p, "title")}
                slug={p.slug}
                description={intl.translate(p, "excerpt")}
                thumbnail={p.featuredImg}/>
            ))}
          </CardGrid>
          <div style={{textAlign: "right", marginTop: "20px"}}>
            <Link to={"/showcases"} className={'ant-btn ant-btn-primary'}>
              {intl.formatMessage({id: "projects_all"})}
            </Link>
          </div>
        </Container>
      </section>
    </Layout>
  )
}

export default ShowcaseDetail

export const query = graphql`
  query($slug: String!) {
    allBudgetShowcase {
      nodes {
        id
        slug
        title_fr
        title_nl
        excerpt_fr
        excerpt_nl
        featuredImg {
          mobile: childImageSharp {
            fluid(maxWidth: 490) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
          desktop: childImageSharp {
            fluid(maxWidth: 1240) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        fields {
          budgetShowcasePosts {
            year
          }
        }
      }
    }
    budgetShowcase(slug: { eq: $slug }) {
      id
      slug
      title_fr
      title_nl
      subtitle_fr
      subtitle_nl
      body_fr
      body_nl
      fields {
        budgetShowcasePosts {
          year
          fields {
            budget {
              expenses
            }
            category {
              originalId
              label_nl
              label_fr
              fields {
                parent {
                  originalId
                  fields {
                    parent {
                      originalId
                      fields {
                        parent {
                          originalId
                          fields {
                            parent {
                              originalId
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
      featuredImg {
        mobile: childImageSharp {
          fluid(maxWidth: 490) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
        desktop: childImageSharp {
          fluid(maxWidth: 1240) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
