import * as React from "react"
import {Button, Card, Tag} from "antd"
import cardStyle from "../../less/projectcards.module.less"
import {Link} from "gatsby-plugin-intl"
import {navigate} from "@reach/router"
import useIntl from "../../helpers/useIntl"
import PropTypes from "prop-types"
import Img from "gatsby-image"
import ArrowRightOutlined from "@ant-design/icons/lib/icons/ArrowRightOutlined"

const ProjectCard = ({
                       title,
                       description,
                       categories,
                       slug,
                       thumbnail,
                       headingLevel = 4,
                     }) => {
  const intl = useIntl()
  let thumbnailSources

  if (thumbnail && thumbnail.mobile && thumbnail.desktop) {
    thumbnailSources = [
      thumbnail.mobile.fluid,
      {
        ...thumbnail.desktop.fluid,
        media: `(min-width: 750px)`,
      },
    ]
  }

  const CustomHeading = `h${headingLevel}`

  return (
    <Card
      className={cardStyle.card}
      hoverable
      onClick={() => navigate(`/${intl.locale}/showcase/${slug}/`)}
      cover={
        thumbnailSources ? (
          <div style={{position: "relative"}}>
            <Img fluid={thumbnailSources} style={{
              width: "100%",
              height: "180px",
            }}
                 alt=''/>
            <CustomHeading style={{display: 'none'}}>{title}</CustomHeading>
            <div className={cardStyle.tags}>
              {categories.map((category, i) => (
                category.fields.category ? (
                  <Tag color="#DE2E21" key={i} className={cardStyle.tag}>
                    {intl.translate(category.fields.category, 'label')}
                  </Tag>
                ) : ''
              ))}
            </div>
          </div>
        ) : (
          ""
        )
      }
    >
      <span className={`ant-card-meta-title h4`}>
        <Link to={`/showcase/${slug}/`}>{title}</Link>
      </span>
      <div className={cardStyle.description}
           dangerouslySetInnerHTML={{__html: description}}/>
      <div className={cardStyle.cardFooter}>
        <Button type={"link"} style={{margin: "0.4rem 0rem"}}
                tabIndex={-1}
                aria-hidden={true}>
          {intl.formatMessage({id: "general_readmore"})}
          <ArrowRightOutlined role='' aria-label=''/>
        </Button>
      </div>
    </Card>
  )
}

ProjectCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  thumbnail: PropTypes.object,
  categories: PropTypes.array,
}

ProjectCard.defaultProps = {
  title: "",
  description: "",
  thumbnail: {},
  categories: [],
}

export default ProjectCard
